import React, { useEffect, useState } from 'react';
import css from './Sidebar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faTicket,
  faList,
  faGear,
  faRightToBracket,
  faAngleDown,
  faEnvelope,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import NamedLink from '../NamedLink/NamedLink';
import { FormattedMessage } from 'react-intl';
import { isUserCompany } from '../../util/data';
import classNames from 'classnames';

const Sidebar = props => {
  const { onLogout, currentUser, notificationCount } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(notificationCount || 0);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);

  // Track window width changes
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Update unread count when new notifications arrive
  useEffect(() => {
    setUnreadCount(notificationCount);
  }, [notificationCount]);
  const tabs = [
    {
      name: 'ProfileSettingsPage',
      icon: faUser,
      text: <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />,
    },
  ];

  if (isUserCompany(currentUser)) {
    tabs.unshift(
      {
        name: 'ManageListingsPage',
        icon: faList,
        text: <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />,
      },
      {
        name: 'RegistrationPage',
        icon: faTicket,
        text: <FormattedMessage id="UserNav.registration" />,
      }
    );
    if (windowWidth < 1024) {
      tabs.unshift({
        name: 'InboxPage',
        icon: faEnvelope,
        text: <FormattedMessage id="TopbarDesktop.inbox" />,
        params: { tab: 'sales' },
        dot: unreadCount > 0 ? true : false
      });
    }
  } else {

    if (windowWidth < 1024) {
      tabs.unshift({
        name: 'InboxPage',
        icon: faEnvelope,
        text: <FormattedMessage id="TopbarDesktop.inbox" />,
        params: { tab: 'orders' },
        dot: unreadCount > 0 ? true : false,
      });
    }
    tabs.unshift({
      name: 'MyTournamentPage',
      icon: faTrophy,
      text: <FormattedMessage id="TopbarDesktop.myTournaments" />,
    });
  }
  const tabsMenu = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      id: 'ContactDetailsPageTab',
      linkProps: { name: 'ContactDetailsPage' },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      id: 'PasswordChangePageTab',
      linkProps: { name: 'PasswordChangePage' },
    },
  ];

  if (isUserCompany(currentUser)) {
    tabsMenu.push(
      {
        text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentSettingsTabTitle" />,
        id: 'PaymentSettingsPageTab',
        linkProps: { name: 'PaymentSettingsPage' },
      },
      {
        text: (
          <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.companyInformationTabTitle" />
        ),
        id: 'CompanyInformationPageTab',
        linkProps: { name: 'CompanyInformationPage' },
      },
      {
        text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.eventCalenderTitle" />,
        id: 'TournamentCalenderPageTab',
        linkProps: { name: 'TournamentCalenderPage' },
      }
    );
  } else {
    tabsMenu.push({
      text: (
        <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.tradingCardAccountsTabTitle" />
      ),
      id: 'TradingCardAccountsPageTab',
      linkProps: { name: 'TradingCardAccountsPage' },
    });
  }
  return (
    <div className={css.sidebar}>
      <ul>
        {tabs.map((tab, index) => (
          <NamedLink key={index} name={tab.name} params={tab.params || {}}>
            <div className={css.iconContainer}>
              <FontAwesomeIcon className={css.icon} icon={tab.icon} />
              {tab.dot ? <div className={css.notificationDot}></div> : null}
            </div>
            {tab.text}
          </NamedLink>
        ))}
        <li
          className={classNames(css.accountSettingLink, { [css.accountSettingLinkActive]: isOpen })}
          onClick={toggleMenu}
        >
          <FontAwesomeIcon className={css.icon} icon={faGear} />
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          <span className={classNames(isOpen && css.arrowIconUp, css.arrowIcon)}>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </li>
        {isOpen && (
          <ul className={css.submenu}>
            {tabsMenu.map((tab, index) => {
              return (
                <NamedLink key={index} name={tab.linkProps.name}>
                  <span className={css.blankSpace}></span>
                  {tab.text}
                </NamedLink>
              );
            })}
          </ul>
        )}
        <li className={css.logoutLink} onClick={onLogout}>
          <FontAwesomeIcon className={css.icon} icon={faRightToBracket} />
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
